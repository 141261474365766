import * as React from "react";
import parse from "html-react-parser";
import { withRouter, useParams } from "react-router-dom";
import AkkrediteringLeftHtml from "./AkkrediteringLeftHtml";
import AkkrediteringRightHtml from "./AkkrediteringRightHtml";

class AkkrediteringAfgoerlser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    this.getData = this.getData.bind(this);
  }

  getData = () => {
    let self = this;
    fetch("../StaticWebsiteData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        self.setState({ data: myJson });
        return myJson;
      });
  };

  componentWillMount() {
    this.getData();
    console.log("Sektor = ", this.props.match.params.sektor);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(function () {
      this.right.style.display = "block";
    }, 1000);
  }
  linkedClicked = (value) => {
    console.log("value", value);
    this.props.parentCallback(value);
  };

  render() {
    if (this.state.data === null || typeof this.state.data === "undefined") {
      // Render loading state ...
      return <div className="row"></div>;
    } else {
      return (
        <div className="row row-switch">
          {this.state.data
            .filter((t) => t.sektor === this.props.match.params.sektor)
            .map((item) => {
              return (
                <div className="col-md-7" id="Center">
                  {" "}
                  <h1></h1>
                  <h4>Akkrediteringsnævnets afgørelser for {item.sektor}</h4>
                  <div style={{ whiteSpace: "pre-wrap" }}></div>
                  {item.institutionData
                    .filter((t) => new Date(t.gyldighedsSlutDato) >= new Date())
                    .map((institution) => {
                      return (
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          <p>
                            <div
                              style={{
                                fontWeight: "600",
                                cursor: "pointer",
                                color: "darkblue",
                              }}
                              onClick={() => {
                                this.props.parentCallback(institution);
                                this.props.history.push("/AfgoerlserDetaljer");
                              }}
                            >
                              {institution.institution}
                              <br />
                              {institution.adresse},{" "}
                              {institution.postnummer_og_distrikt}
                            </div>

                            {this.props.match.params.sektor !== "Apoteker" &&
                            this.props.match.params.sektor !==
                              "Praehospital" ? (
                              <>
                                {institution.yderNr !== "" ? (
                                  <p>Ydernummer - {institution.yderNr}</p>
                                ) : (
                                  <></>
                                )}
                                <br />
                              </>
                            ) : (
                              <></>
                            )}

                            {institution.akkrediteringsStatus}
                          </p>

                          <p>{parse(institution.enhedslistertext)}</p>

                          <hr />
                        </div>
                      );
                    })}
                </div>
              );
            })}
          <div
            className="col col-md-5"
            id="right"
            ref="right"
            style={{ display: "none" }}
          >
            <h1></h1>
            <AkkrediteringRightHtml
              dataTitle={this.props.dataTitle}
              sektor={this.props.match.params.sektor}
            />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(AkkrediteringAfgoerlser);
