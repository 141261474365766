import * as React from 'react';

class Footer extends React.Component {

    render() {
        return(
            <footer> 
             
            </footer>
        )
    }
}

export default Footer;