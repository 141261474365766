import * as React from 'react';

class IkasOffentligeSygehuse extends React.Component {

    render() {
        return(
            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>Her kommer der info om offentlige sygehuse</div>
                <div className='col-md-3'></div>
            </div>
        )
    }
}

export default IkasOffentligeSygehuse;