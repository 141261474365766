import * as React from 'react';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
class AkkrediteringDetaljer extends React.Component {
    render() {
        var institution = this.props.dataItem;
        return(
          <div className="row">
           
            <div className="col-md-10" id="Center">
            {
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <p>
                <div style={{ fontWeight: '600', cursor:'pointer', color: 'darkblue'}} >{institution.institution}
                <br />
                {institution.adresse}, {institution.postnummer_og_distrikt}
                </div>
                </p>
                
                <p>{parse(institution.enhedslistertext)}
                <br />
                <span class="inverted">Nuværende status: {institution.akkrediteringsStatus} </span>
                <h5>DDKM for {institution.sektor} - {institution.standardVersion}. version</h5>
                </p>
                <p>Gyldighedsperiode: {new Date(institution.gyldighedsStartDato).toLocaleDateString()} - {new Date(institution.gyldighedsSlutDato).toLocaleDateString()}</p>
                {
                    institution.behandlinger.map(item => {
                      return (
                          <>
                            <strong>{item.behandlingsnr}. behandling:</strong>
                            <p>Status: {item.surveyAkkrediteringsstatus}
                            <br />
                            Gyldighedsperiode: {new Date(institution.gyldighedsStartDato).toLocaleDateString()} - {new Date(institution.gyldighedsSlutDato).toLocaleDateString()}
                            <br />
                            {
                              item.referatDokNr !=="" ? (<div>
                              <FontAwesomeIcon icon={faFile} size="2x"/>&nbsp;&nbsp;
                              <a href={"./Output/" + item.referatDokNr + ".pdf"} target="_blank" rel="noreferrer">Referat fra nævnsmøde</a></div>) : <div></div>
                            }
                            <br /><br />
                            {
                              item.rapportDokNr !== "" ? (<div>
                                <FontAwesomeIcon icon={faFile} size="2x"/>&nbsp;&nbsp;
                                <a href={"./Output/" + item.rapportDokNr + ".pdf"} target="_blank" rel="noreferrer">Rapport fra survey</a></div>) : <div></div>
                            }
                            
                            </p>
                          </>
                      )})
                }

                <hr />
                  
              </div>
            }
            </div>
            <div className="col-md-2" id="Right">
            </div>
          </div >
        );
              
    }
}


export default AkkrediteringDetaljer;

