
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import XMLParser from 'react-xml-parser';

class Forskning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {data: null};
        this.getData= this.getData.bind(this);
      }
    getData=()=>{
        let self = this
       fetch('D18-17267.xml'
      ,{
        headers : { 
          'Content-Type': 'application/xml',
          'Accept': 'application/xml',
          'Charset': 'utf-8'
         }
      }
      )
        .then(function(response){
          return response.text()
        })
        .then(function(myXml) {
          var xml = new XMLParser().parseFromString(myXml);
          self.setState({data: xml})
          return xml
        });
    }

    componentDidMount() {
        this.getData()
    }

    linkedClicked = (value) => {
        this.props.parentCallback(value);
    } 

    htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    
    render() {

        if(this.state.data === null || typeof this.state.data === 'undefined') {
            // Render loading state ...
            return(
                <div className="row">Loader....</div>
            )
        }
        else {
            const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
           
            return(
                <div className="row">
                    <div className="toggle">
                    {
                    
                        this.state.data.children.map(item => {
                            return(
                            <p>
                                 {item.name === "O" ? 
                                 
                                  <h1>{ renderHTML(parse(this.htmlDecode(item.value))) }</h1> :
                                        item.name ==="O2" ? <h2>{ renderHTML(parse(this.htmlDecode(item.value))) }</h2> :
                                            <p>{ renderHTML(parse(this.htmlDecode(item.value))) }</p>}
                                 
                            </p>
                            )
                        })
                        
                    }
                </div>
                </div>
            )
        }
    }
}

export default withRouter(Forskning);