import * as React from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AkkrediteringRightHtml extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], standardData: [] };
  }

  getBilagData = () => {
    let self = this;
    fetch("../standardsaet.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log("test", myJson);
        self.setState({ standardData: myJson });
        return myJson;
      });
  };

  componentDidMount() {
    this.getBilagData();
  }
  render() {
    if (
      this.state.standardData === null ||
      this.state.standardData === [] ||
      typeof this.state.standardData === "undefined"
    ) {
      // Render loading state ...
      return <></>;
    } else {
      return (
        <>
          <div>
            <h4>Tip til søgning på ydernummer eller kliniknavn</h4>
            <ol>
              <li>
                Windows: Tryk "Ctrl + f"
                <br />
                Mac: Tryk "cmd + f"
              </li>
              <li>Indtast søgeord i det fremkomne søgefelt oppe til højre.</li>
              <li>Tryk på "Enter".</li>
            </ol>
          </div>
          <hr />
          {this.state.standardData
            .filter((x) => x.sektor === this.props.sektor)
            .map((item) => {
              return (
                <div
                  className="flexcontainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    gap: "20px",
                  }}
                >
                  {item.bilag.map((bilag) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="itemOverskrift">
                          <h4>{bilag.overskrift}</h4>
                        </div>
                        <div
                          className="item"
                          dangerouslySetInnerHTML={{ __html: bilag.tekst }}
                        ></div>
                        <a href={bilag.link} target="_blank">
                          <div className="itemLinkTekst" style={{ order: 2 }}>
                            <u>{bilag.linkTekst}</u>
                          </div>
                        </a>
                        {typeof bilag.relevantLink !== "undefined" ? (
                          <div
                            className="itemLinkTekst2"
                            dangerouslySetInnerHTML={{
                              __html: bilag.relevantLink,
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </>
      );
    }
  }
}

export default AkkrediteringRightHtml;
