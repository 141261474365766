import * as React from "react";
import { withRouter } from "react-router-dom";
class Frontpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, loaded: false };
  }

  getBilagData = () => {
    let self = this;
    fetch("/frontpage.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        self.setState({ data: myJson, loaded: true });
        return myJson;
      });
  };

  componentDidMount() {
    this.getBilagData();
  }
  render() {
    if (this.state.loaded === false) {
      // Render loading state ...
      return <div className="row"></div>;
    } else {
      return (
        <>
          <h1
            dangerouslySetInnerHTML={{ __html: this.state.data.overskrift }}
          ></h1>
          {/* <p>
                        {
                            this.state.data.afsnit.map(item => {
                                return (

                                    <p> 
                                        {item.link !== "" ? <a href={item.link} target="_blank">{item.tekst}</a> : <p dangerouslySetInnerHTML={{ __html: item.tekst }}></p>}
                                    </p>
                                )
                            })

                        }
                    </p> */}

          <p>
            IKAS er lukket med udgangen af juni 2022. Om nødvendigt kan kontakt
            rettes til Danske Regioner på mail:{" "}
            <a href="mailto:regioner@regioner.dk">regioner@regioner.dk</a>
          </p>

          <p>
            Institut for Kvalitet og Akkreditering i Sundhedsvæsenet (IKAS) har
            i perioden 2005-2022 udviklet og driftet Den Danske KvalitetsModel
            (DDKM)
          </p>

          <p>
            DDKM består i praksis af en række målsætninger for god kvalitet, som
            de enkelte behandlingssteder skal leve op til. Målsætningerne kaldes
            akkrediteringsstandarder. Vurderingen af, i hvilket omfang de
            enkelte behandlingssteder lever op til kravene i standarderne,
            foretages af surveyteams, der udsendes af IKAS. På grundlag heraf
            tildeler Akkrediteringsnævnet en akkrediteringsstatus, der gælder
            for en 3-årig periode.{" "}
          </p>

          <p>
            I 2021 besluttede Danske Regioner, at akkreditering ikke længere
            skulle være et element i de kontrakter, der indgås med
            sygesikringsyderne, og IKAS's bestyrelse besluttede på denne
            baggrund at lukke IKAS med udgangen af juni 2022.
          </p>

          <p>
            Ved at klikke på symbolerne herunder, finder man en oversigt over de
            klinikker/behandlingssteder, der har en akkrediteringsstatus, der
            fortsat er gældende. Den relevante rapport fra surveyorbesøget samt
            det relevante standardsæt vil være tilgængelig.
          </p>

          <br />
          <br />
          <div className="row row-icons">
            <div
              onClick={() => {
                this.props.parentCallback("Almen praksis");
                this.props.history.push("/Afgoerlser/Almen praksis");
              }}
            >
              <img
                src="/img/icons/AP ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Apoteker");
                this.props.history.push("/Afgoerlser/Apoteker");
              }}
            >
              <img
                src="/img//icons/APO ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Fodterapeutpraksis");
                this.props.history.push("/Afgoerlser/Fodterapeutpraksis");
              }}
            >
              <img
                src="/img/icons/FOD ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Fysioterapipraksis");
                this.props.history.push("/Afgoerlser/Fysioterapipraksis");
              }}
            >
              <img
                src="/img/icons/FYS ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Kiropraktorer");
                this.props.history.push("/Afgoerlser/Kiropraktorer");
              }}
            >
              <img
                src="/img/icons/KIRO ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>
          </div>
          <div
            className="row row-icons row-icons-center"
            style={{ marginBottom: "24px" }}
          >
            <div
              onClick={() => {
                this.props.parentCallback("Praktiserende speciallæger");
                this.props.history.push(
                  "/Afgoerlser/Praktiserende speciallæger"
                );
              }}
            >
              <img
                src="/img/icons/SP ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Privathospitaler og klinikker");
                this.props.history.push(
                  "/Afgoerlser/Privathospitaler og klinikker"
                );
              }}
            >
              <img
                src="/img/icons/PH ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Praehospital");
                this.props.history.push("/Afgoerlser/Praehospital");
              }}
            >
              <img
                src="/img/icons/Præhospital ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>

            <div
              onClick={() => {
                this.props.parentCallback("Privatpraktiserende psykologer");
                this.props.history.push(
                  "/Afgoerlser/Privatpraktiserende psykologer"
                );
              }}
            >
              <img
                src="/img/icons/Psyk ikon NY.png"
                style={{ height: "148px", width: "auto", cursor: "pointer" }}
                alt="Billede"
              />
            </div>
          </div>
          <br />
          <p>
            For særligt interesserede har vi gjort en række dokumenter, der
            beskriver baggrunden for DDKM og IKAS, tilgængelige via dette{" "}
            <a href="./YderligereInfoListe">
              <b>link</b>
            </a>
            . Her vil man også kunne finde materiale, der kunne være relevant
            for det videre kvalitetsarbejde i klinikken, ligesom der vil være
            materiale tilgængelig vedrørende akkrediteringsforløbet i offentlige
            sygehuse.
          </p>
        </>
      );
    }
  }
}

export default withRouter(Frontpage);
