import * as React from 'react';

class YderligereInfoListe extends React.Component {

    render() {
        return(
        <>
        <h1>På denne side kan du læse mere om.....</h1>
        <h4><a href="./omddkm">Om DDKM</a><br /></h4>
        <h4><a href="./omikas">Om IKAS</a><br /></h4>
        <h4><a href="./RelevantLitteratur">Forskning om akkreditering</a><br /></h4>
        </>
        )
    }
}

export default YderligereInfoListe;