import * as React from "react";

class IkasRelevantLitteratur extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <h1>Forskning om akkreditering</h1>
          <h2>Videnskabelige artikler om akkreditering i Danmark</h2>
          <p>
            De fleste artikler herunder er der fri adgang til. Nogle artikler
            kan kun tilgås, hvis man har abonnement eller på anden måde købt
            adgang til det pågældende tidsskrift.
          </p>
          <p>
            Jensen MLO et al: Implementation of healthcare accreditation in
            Danish general practice: a questionnaire study exploring general
            practitioners’ perspectives on external support. Scandinavian
            Journal of Primary Health Care 2021.
            <br />
            <a
              href="https://doi.org/10.1080/02813432.2021.1882084"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1080/02813432.2021.1882084
            </a>
            . FRI ADGANG
          </p>
          <p>
            Rikke Marthinsen og Jesper Gad Christensen: Accreditation of general
            practices and specialist private practices in Denmark: who performs
            better and why? The Danish Institute for Quality and Accreditation
            in Healthcare (IKAS). Presented at ISQua&#39;s 36th International
            Conference - Cape Town, South Africa October 2019.11.19.
            <br />
            <a
              href="/bilag/ftp/D19-12135.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ikas.dk/bilag/ftp/D19-12135.pdf
            </a>
            . FRI ADGANG
          </p>
          <p>
            A Mygind, PP Munksgaard, F Bro: Regional støtte til implementering
            af akkreditering i almen praksis. Udbud, deltagelse og
            anvendelighed. Rapport fra Forskningenheden for Almen Praksis i
            Aarhus. Juli 2019.
            <br />
            Link til rapport. FRI ADGANG
          </p>
          <p>
            MB Kousgaard et al: Experience of accreditation impact in general
            practice - a qualitative study among general practitioners and their
            staff. BMC Family Practice 2019, 20, 146.
            <br />
            <a
              href="https://doi.org/10.1186/s12875-019-1034-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1186/s12875-019-1034-4
            </a>
            . FRI ADGANG
          </p>
          <p>
            TD Due et al: Understanding accreditation standards in general
            practice - a qualitative study. BMC Family Practice 2019, 20, 23.
            <br />
            <a
              href="https://doi.org/10.1186/s12875-019-0910-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1186/s12875-019-0910-2
            </a>
            . FRI ADGANG
          </p>
          <p>
            VIVE-rapport 2019: Marie Henriette Madsen og Sarah Wadmann.
            Forankring af akkreditering i speciallægepraksis. Et kvalitativt
            casestudie.
            <br />
            <a
              href="https://www.vive.dk/media/pure/13417/2958771"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.vive.dk/media/pure/13417/2958771
            </a>
            . FRI ADGANG
          </p>
          <p>
            Andersen MK et al: Retirement, job satisfaction and attitudes
            towards mandatory accreditation: a Danish survey study in general
            practice. BMJ Open 2018;8:e020419. doi: 10.1136/bmjopen-2017-
            020419.
            <br />
            <a
              href="http://dx.doi.org/10.1136/bmjopen-2017-020419"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://dx.doi.org/10.1136/bmjopen-2017-020419
            </a>
            . FRI ADGANG
          </p>
          <p>
            A Nicolaisen et al: Managers’ perceptions of the effects of a
            national mandatory accreditation program in Danish hospitals. A
            cross-sectional survey. Int J Qual Health Care 2019, 31, 331-337.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzy174"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzy174
            </a>
          </p>
          <p>
            Bogh, SB et al: Hospital accreditation: staff experiences and
            perceptions. International Journal of Health Care Quality Assurance
            2018, 5, 420-427.
            <br />
            <a
              href="https://doi.org/10.1108/IJHCQA-06-2017-0115"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1108/IJHCQA-06-2017-0115
            </a>
          </p>
          <p>
            AM Falstie-Jensen et al: Consecutive cycles of hospital
            accreditation: Persistent low compliance associated with higher
            mortality and longer length of stay. Int J Qual Health Care 2018,
            30, 382-389.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzy037"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzy037
            </a>
            . FRI ADGANG.
          </p>
          <p>
            LB Pedersen et al: Can external interventions crowd in intrinsic
            motivation? A cluster randomised field experiment on mandatory
            accreditation of general practice in Denmark. Social Science &amp;
            Medicine 2018, 211, 224-233.
            <br />
            <a
              href="https://doi.org/10.1016/j.socscimed.2018.06.023"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1016/j.socscimed.2018.06.023
            </a>
            . FRI ADGANG.
          </p>
          <p>
            MK Andersen et al: Accreditation in general practice in Denmark:
            study protocol for a cluster- randomized controlled trial. Trials
            2017, 18, 69.
            <br />
            <a
              href="https://doi.org/10.1186/s13063-017-1818-6"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1186/s13063-017-1818-6
            </a>
            . FRI ADGANG
          </p>
          <p>
            LH Ehlers et al: Attitudes towards accreditation among hospital
            employees in Denmark: a cross-sectional survey. Int J Qual Health
            Care 2017, 29, 693-698.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzx090"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzx090
            </a>
            . FRI ADGANG.
          </p>
          <p>
            AM Falstie-Jensen et al: Compliance with accreditation and
            recommended hospital care - a Danish nationwide population-based
            study. Int J Qual Health Care 2017, 29, 625-633.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzx104"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzx104
            </a>
            . FRI ADGANG.
          </p>
          <p>
            SB Bogh et al: Predictors of the effectiveness of accreditation on
            hospital performance: A nationwide stepped-wedge study. Int J Qual
            Health Care 2017, 29, 477-483.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzx052"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzx052
            </a>
            . FRI ADGANG.
          </p>
          <p>
            LH Ehlers et al: Unannounced versus announced hospital surveys: a
            nationwide cluster-randomized controlled trial. Int J Qual Health
            Care 2017, 29, 406-411.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzx039"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzx039
            </a>
          </p>
          <p>
            Engel C, Andersen HB. Side effects of overdoing it: lessons from a
            comprehensive hospital accreditation programme. In: Researching
            Patient Safety and Quality in Healthcare: A Nordic Perspective. Eds:
            Aase K, Schibevaag L, CRC Press, 2017.
            <br />
            <i>
              Dette er et kapitel i en bog - det er desværre ikke muligt at
              lægge et link.
            </i>
          </p>
          <p>
            SB Bogh et al: Improvement in quality of hospital care during
            accreditation: A nationwide stepped- wedge study. Int J Qual Health
            Care 2016, 28, 715-720.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzw099"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzw099
            </a>
            . FRI ADGANG
          </p>
          <p>
            FB Waldorff et al: Almost half of the Danish general practitioners
            have negative a priori attitudes towards a mandatory accreditation
            programme. Dan Med J 2016, 63(9), A5266.
            <br />
            <a
              href="http://ugeskriftet.dk/dmj/almost-half-danish-general-practitioners-have-negative-priori-attitudes-
                        towards-mandatory"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://ugeskriftet.dk/dmj/almost-half-danish-general-practitioners-have-negative-priori-attitudes-
              towards-mandatory
            </a>
          </p>
          <p>
            AM Falstie-Jensen et al: Is compliance with hospital accreditation
            associated with length of stay and acute readmission: A Danish
            nationwide population-based study. Int J Qual Health Care 2015, 27,
            451- 458.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzv070"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzv070
            </a>
            . FRI ADGANG.
          </p>
          <p>
            SB Bogh et al: Accreditation and improvement in process quality of
            care: a nationwide study. Int J Qual Health Care 2015, 27, 336-343.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzv053"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzv053
            </a>
            . FRI ADGANG.
          </p>
          <p>
            AM Falstie-Jensen et al: Compliance with hospital accreditation and
            patient mortality: A Danish nationwide population-based study. Int J
            Qual Health Care 2015, 27, 165-174.
            <br />
            <a
              href="https://doi.org/10.1093/intqhc/mzv023"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1093/intqhc/mzv023
            </a>
            . FRI ADGANG.
          </p>
          <p>
            KB Simonsen et al: Unannounced or announced periodic hospital
            surveys: a study protocol for a nationwide cluster-randomized
            controlled trial. Comparative Effectiveness Research 2015, 5, 57-63.{" "}
            <br />
            <a
              href="https://doi.org/10.2147/CER.S86740"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.2147/CER.S86740
            </a>
            . FRI ADGANG.
          </p>
          <p>
            P Triantafillou. Against all odds? Understanding the emergence of
            accreditation of the Danish hospitals. Social Science &amp;
            Medicine, 2014, 101, 78-85.
            <br />
            <a
              href="https://doi.org/10.1016/j.socscimed.2013.11.009"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://doi.org/10.1016/j.socscimed.2013.11.009
            </a>
          </p>
          <h2>Oplæg og posters ved konferencer m.m.</h2>
          <p>
            ISQua-webinar 14 Feb 2020 ”Accreditation as a driver of improvement
            and knowledge transfer”: Link. Accreditation of general practices in
            Denmark: findings from surveys and outcomes after the opportunity to
            improve.
            <br />
            <a
              href="/bilag/ftp/D17-9072.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.ikas.dk/bilag/ftp/D17-9072.pdf
            </a>
          </p>
          <p>
            Akkreditering af almen praksis i Danmark - resultater efter eksternt
            survey og efter opfølgning <br />
            <a
              href="/bilag/ftp/D18-20156.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.ikas.dk/bilag/ftp/D18-20156.pdf
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default IkasRelevantLitteratur;
