import * as React from "react";

class IkasOmIkas extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <h1>Om IKAS</h1>
          <p>
            Institut for Akkreditering og Kvalitetsudvikling i Sundhedsvæsenet,
            IKAS, blev etableret medio 2005 af de stiftende parter bag Den
            Danske Kvalitetsmodel, DDKM. IKAS blev oprettet med henblik på at
            udvikle og drive modellen. DDKM var et aftalebaseret
            kvalitetssystem, der skulle være med til at sikre kvaliteten i de
            helt eller delvist offentligt finansierede sundhedsydelser.
          </p>
          <p>
            IKAS akkrediterede virksomheder i forskellige dele af
            sundhedsvæsenet. Akkreditering var en del af de aftaler, der blev
            indgået mellem det offentlige, der betaler, og dem, der
            leverede\public_html\StaticWebsiteData.json ydelserne (fx klinikker,
            privathospitaler, apoteker).
          </p>
          <p>
            IKAS havde hovedkontor i Aarhus på adressen Olof Palmes Allé 13, 1.
            th i Aarhus N. I oktober 2014 etablerede IKAS en filial i København
            på Dampfærgevej 27-29.
          </p>

          <p>Direktør i perioden 2010-2022 var Jesper Gad Christensen.</p>
        </div>
      </div>
    );
  }
}

export default IkasOmIkas;
