import * as React from 'react';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {afgoerelseAktiv: false};
        
    }
    componentDidMount()
    {
        if(window.location.pathname.toLowerCase() === "/afgoerlseliste")
        {
            this.setState({afgoerelseAktiv: true})
        }
    }
    render() {
        
        return(
            <>
            <header id="header">
              <div className="container">
                  <div className="logo">
                      <a href="" onClick={() => {this.props.history.push("/")}}>
                          <img src="/img/logo.png"  style={{height: '100%', width: 'auto'}} alt="Billede"/>
                      </a>
                  </div>
                 
              </div>
{/*               
              {<div className="navbar-collapse nav-main-collapse">
                <div className="container">
                  
                    <nav className="nav-main mega-menu">
                        <ul className="nav nav-pills nav-main" id="mainMenu">
                            <li className={`${this.state.afgoerelseAktiv && window.location.pathname !== "/"  ? 'active': ' '}`} 
                            onClick={() => {this.setState({afgoerelseAktiv: true});  this.props.history.push("/AfgoerlseListe")}} >
                                    <a style={{cursor:"pointer"}}>
                                        Afgørelser
                                    </a>
                            </li>
                            <li className={`${!this.state.afgoerelseAktiv && window.location.pathname !== "/" ? 'active': ' '}`}
                            onClick={() => {this.setState({afgoerelseAktiv: false});   this.props.history.push("/Forskning")}} >

                                    <a  style={{cursor:"pointer"}}>
                                        Forskningsresultater m.v.
                                    </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                
            </div>} */}
            
            </header>
            <div role="main" className="main">
                <section className="page-top">
                    <div className="container">
                    </div>
                </section>
            </div>
          </>
        )
    }
}

export default  withRouter(Header);
