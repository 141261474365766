import * as React from 'react';
import AkkrediteringSektorList from './components/AkkrediteringSektorList.js';
import AkkrediteringDetaljer from './components/AkkrediteringDetaljer.js';
import AkkrediteringAfgoerlser from './components/AkkrediteringAfgoerelser.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Forskning from './components/Forskning.js';
import Frontpage from './components/Frontpage.js';
import IkasOmDDKM from './components/IkasOmDDKM.js';
import IkasOmIkas from './components/IkasOmIkas.js';
import IkasRelevantLitteratur from './components/IkasRelevantLitteratur.js';
import IkasOffentligeSygehuse from './components/IkasOffentligeSygehuse.js';
import YderligereInfoListe from './components/IkasYderligereInfoLise.js';

import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';



class App extends React.Component {
  
  constructor(props)
  {
    super(props)
    this.state = {sektor:"Apoteker", afgoerlser: []}
    this.callbackFunction = this.callbackFunction.bind(this);
    this.callbackAfgoerlserFunction = this.callbackAfgoerlserFunction.bind(this);
  }
  callbackFunction = (childData) => {
    console.log("clicked = ", childData)
    this.setState({sektor: childData})
  }

  callbackAfgoerlserFunction = (childData) => {
    console.log("clicked Afgoerlser = ", childData)
    this.setState({afgoerlser: childData})
  }

  render()
  {
    return (
      <div className="App body">
        
        <Router>
            <Header />
            <div className="container" id="Content">
            <Switch>
                <Route exact path='/' component={() => <Frontpage parentCallback = {this.callbackFunction}/>} />
                <Route exact path='/AfgoerlseListe' component={() => <AkkrediteringSektorList  parentCallback = {this.callbackFunction}/>} />
                <Route path='/Afgoerlser/:sektor' component={() => <AkkrediteringAfgoerlser dataTitle={this.state.sektor}  parentCallback = {this.callbackAfgoerlserFunction}/>} />
                <Route path='/AfgoerlserDetaljer' component={() => <AkkrediteringDetaljer dataItem={this.state.afgoerlser} /> } />
                <Route path='/Forskning' component={() => <Forskning /> } />
                <Route path='/OmDDKM' component={() => <IkasOmDDKM /> } />
                <Route path='/OmIKAS' component={() => <IkasOmIkas /> } />
                <Route path='/OffentligeSygehuse' component={() => <IkasOffentligeSygehuse /> } />
                <Route path='/RelevantLitteratur' component={() => <IkasRelevantLitteratur /> } />
                <Route path='/YderligereInfoListe' component={() => <YderligereInfoListe /> } />
                <Route path='*' component={() => <Frontpage parentCallback = {this.callbackFunction}/>  } />
            </Switch>
            </div>
        </Router>
        
        <Footer />
      </div>
    );
  }
}
export default App;
