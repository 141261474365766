import * as React from 'react';

class IkasOmDDKM extends React.Component {

    render() {
        return(
            <div className='row'>
                <div className='col-lg-12'>
                    <h1>Om DDKM</h1>
                    <h2>Hvad er DDKM og hvad er hensigten med DDKM</h2>
                    <p>
                        Den Danske Kvalitetsmodel, DDKM, var en del af udmøntningen af &quot;National Strategi for Kvalitetsudvikling i
                        Sundhedsvæsenet 2002-2006&quot;. DDKM blev formelt etableret i november 2004 gennem en samarbejdsaftale
                        mellem Indenrigs- og Sundhedsministeriet, Amtsrådsforeningen og Hovedstadens Sygehusfællesskab. DDKM
                        var et nationalt system til kvalitetsudvikling på tværs af alle sektorer i sundhedsvæsenet. Hensigten var, at
                        DDKM skulle bidrage til at:<br /><br />
                        <ul>
                            <li>sikre løbende udvikling af kvaliteten i alle offentligt finansierede sundhedsydelser<br /></li>
                            <li>skabe bedre og mere sammenhængende patientforløb<br /></li>
                            <li>forebygge fejl og utilsigtede hændelser i sundhedsvæsenet<br /></li>
                        </ul><br />
                        Den Danske Kvalitetsmodel, DDKM, bestod i praksis af en række målsætninger for god kvalitet, som de
                        enkelte behandlingssteder skulle leve op til. Målsætningerne kaldes akkrediteringsstandarder.<br />
                        I DDKM var der akkrediteringsstandarder, der beskrev organisatoriske forhold, der er vigtige for, at
                        behandlingsstedet kan levere god kvalitet. Det kan fx være, hvordan man overvåger og udvikler kvaliteten af
                        sine ydelser, og hvordan man sikrer, at arbejdet udføres af kompetente medarbejdere.<br />
                        Andre akkrediteringsstandarder beskrev vigtige dele af et patientforløb. Det kan fx være, hvordan man
                        planlægger et behandlingsforløb og følger op på planen, hvordan man sikrer sig, at der er styr på prøvesvar,
                        så man kan efterlyse manglende svar, og reagerer prompte på svar, der kræver hurtig handling, eller hvordan
                        man håndterer medicin.<br />
                        Endelig var der akkrediteringsstandarder, der beskrev, hvordan man skal tilrettelægge sin behandling ud fra
                        den bedste faglige viden, og hvordan man følger op på dette.
                    </p>
                    <br />
                    <p>
                        Det enkelte behandlingssted kan bruge standarderne som en spørgeguide, når man vil undersøge, om man
                        kan leve op til målene. Man kan på den måde komme omkring vigtige dele af sin virksomhed og finde
                        eventuelle udfordringer. Standarderne vil også kunne vejlede om, hvordan udfordringerne kan mødes.
                        Ved eksternt survey fik behandlingsstedet besøg af kolleger, som var særligt uddannet til at undersøge,
                        hvordan stedet levede op til akkrediteringsstandarderne. Dette skete hvert tredje år.<br />
                        Efter surveyet tildelte et uafhængigt akkrediteringsnævn en akkrediteringsstatus til behandlingsstedet. Status
                        viste, i hvor høj grad stedet levede op til de kvalitetsmål, som akkrediteringsstandarderne sætter.
                    </p>
                    <br />
                    <h2>Hvad er akkreditering</h2>
                    <p>Akkreditering kan defineres som:<br />
                        &quot;Kvalitetsvurdering, hvor et anerkendt organ vurderer, hvorvidt en aktivitet, ydelse eller institution lever op til
                        et sæt af fælles standarder.&quot;<br />
                        (Det Nationale Begrebsråd for Sundhedsvæsenet, 2006).</p>
                    <br />
                    <p>I Danmark var det institutioner eller virksomheder, der blev akkrediteret, men man kunne også forestille sig
                       akkreditering af ydelser, fx af patientforløb på tværs af sektorgrænser.</p><br />

                    <p> Akkreditering i sundhedsvæsenet har en lang historie og en stor udbredelse. Det startede med sygehuse. I
                        USA blev grunden til det, der i dag er Joint Commission, allerede lagt i 1917 (Roberts JS, Coale JG, Redman
                        RR. A History of the Joint Commission on Accreditation of Hospitals. JAMA 1987; 258: 936-40). I 1953
                        startede et nationalt program i Canada, og i Australien i 1974. Helt fra begyndelsen har formålet være at
                        sikre, at sygehusene lever op til grundlæggende krav, som er nødvendige for, at man kan levere ydelser af en
                        god kvalitet.<br />
                        Efterhånden har akkreditering bredt sig til andre dele af sundhedssektoren. I dag kan man finde eksempler
                        på akkreditering i alle dele af sektoren. I begyndelsen af 2017 var der ISQua-akkrediterede
                        akkrediteringsorganisationer i 23 lande; mange af dem arbejder internationalt, så akkreditering anvendes i
                        endnu flere lande.</p>
                    <br />
                    <p>

                        Akkreditering er en formel udefrakommende vurdering, som foretages med en defineret metode og på
                        grundlag af offentliggjorte standarder. Dermed er akkrediteringsprocessen transparent. Men hvad det
                        egentlig går ud på, kan man først forstå ved at se på, hvad det er, standarderne siger.
                        <br /><b>ISQua - International Society for Quality in Health Care - sætter på verdensplan rammen for
                        akkreditering i sundhedsvæsenet</b>. Standardernes indhold fastlægges overordnet af et sæt Guidelines
                        and Principles for the Developmet of Health and Social Care Standards. Der er seks principper. De to første
                        handler om, at standarder skal udvikles evidensbaseret og med involvering af interessenter, og om at der skal
                    </p>

                    <p>
                        <br />
                        <ul>
                            <li>
                                Standarderne skal sikre, at de rette ressourcer og kompetencer er til rådighed, at patienter/brugere
                                involveres, at de forskellige aktører koordinerer deres indsats, og at organisationen sætter sig mål og
                                følger op på, om de nås. Der skal være en veldefineret og beskrevet ledelsesstruktur. Personalets
                                kompetencer skal sikres. Evidensbaserede retningslinjer skal efterleves.<br />
                            </li>
                            <li>
                                Standarderne skal sikre, at organisationerne arbejder systematisk med risikostyring og følger op på
                                utilsigtede hændelser, at personalet uddannes i sikkerhed, at bygninger og forsyninger er i orden, at
                                nosokomielle infektioner forebygges, at arbejdsmiljøet sikres og at der er ordnede journaler.<br />
                            </li>
                            <li>
                                Standarderne skal sikre patienters rettigheder, håndtering af klager, inddragelse af patienter, hensyn
                                til patienters kulturelle og åndelige behov, tilgængelighed af ydelser, relevant udredning,
                                behandlingsplaner og velfungerende overgange til andre organisationer.<br />
                            </li>
                            <li>
                                Der kræves en systematisk tilgang til kvalitetsudvikling, både overvågning af kvaliteten og
                                forbedringstiltag. Kvalitetsovervågningen skal omfatte både ledelsesaktiviteter, organisatoriske
                                forhold og klinisk kvalitet. Der skal være en systematisk tilgang til dokumentstyring af retningslinjer
                                m.v.<br />
                            </li>
                        </ul><br />
                    </p>
                    <p>
                        ISQua-principperne tilstræber at komme rundt om de væsentlige forudsætninger for, at en organisation kan
                        levere sundhedsydelser af god kvalitet. Det er en grundtanke i principperne, at det ikke er muligt uden en
                        tydelig ledelse.<br /><br />
                    </p>
                    <p>
                        Flere af IKAS’ akkrediteringsstandardsæt var akkrediterede efter disse principper, men i nogle
                        akkrediteringsstandardsæt har man valgt at fokusere på en delmængde af det indhold, som kræves af
                        ISQua, typisk forhold, der har gøre med krav i lovgivning og overenskomster. Derfor var ikke alle
                        akkrediteringsstandardsættene ISQua-akkrediterede.<br /><br />
                    </p>
                    <p>
                        Det er vigtigt at gøre sig klart, at akkrediteringsstandarder ikke er det samme som forskrifter. De siger ikke
                        altid præcis, hvad man skal gøre. I stedet kan man bruge akkrediteringsstandarderne som en spørgeguide,
                        når man vil undersøge, om man kan leve op til målene. Man kan på den måde komme omkring vigtige dele
                        af sin virksomhed og finde eventuelle udfordringer. Akkrediteringsstandarderne vil også kunne vejlede om,
                        hvordan udfordringerne kan mødes.<br /><br />
                    </p>
                    <p>
                        Man møder undertiden den opfattelse, af hensigten med akkreditering er, at alle processer og arbejdsgange
                        skal beskrives i detaljer, at det skal dokumenteres, at der arbejdes efter forskrifterne, og at der skal
                        indsamles data, så man til enhver tid kan vise, at alle forskrifter er blevet overholdt hver gang.
                        <br /><br /></p>
                    <p>
                        Begge disse opfattelser er misforståelser.<br /><br />
                    </p>
                    <p>
                        Derimod er det en grundtanke bag akkreditering, at man ikke kan forstå sine resultater, fastholde de gode
                        resultater, man opnår, og forbedre det, der skal forbedres, hvis man ikke interesserer for de strukturer og
                        processer, der leverer resultaterne. Men det er ikke nok - man skal selvfølgelig også kende resultaterne.
                        Nogle arbejdsgange kan med fordel standardiseres - tænk blot på håndtering af hjertestop - men ofte skal
                        man anvende en ”byggeklodstænkning”, altså ikke ”alle skal ernæringsscreenes”, men ”når vi
                        ernæringsscreener, gør vi det på den-og-den måde og dokumenterer det sådan-og-sådan”. Man kan så
                        bestemme, at man altid anvender den byggeklods i bestemte patientforløb, og i øvrigt når sundhedspersoner
                        konkret finder det begrundet.<br /><br />
                    </p>
                    <p>
                        Selv om det er en del af survey, at visse dokumenter gennemgås, er hoveddelen af et survey interviews med
                        ledere og medarbejdere, demonstration af arbejdsgange, og forevisning af eksempler fra den daglige praksis.
                        Dette giver et kvalitativt indblik i, hvordan arbejdsgangene fungerer, som ofte kan gøre det overflødigt at
                        overvåge arbejdsgangen kontinuerligt.<br /><br />
                    </p>
                    <h2>Etableringen af DDKM i 2004 og i de følgende år</h2>
                    <p>
                        I begyndelsen af 90’erne opstod der et ønske om at samle indsatsen for kvalitet, hvilket førte til den
                        ”Nationale Strategi for kvalitetsudvikling i sundhedsvæsenet 2002-2006”.<br />
                        Strategiens overordnede mål var at bidrage til en høj kvalitet både i forhold til sundhedsydelser og med
                        hensyn til at sikre kontinuitet og sammenhæng i patientforløb. Strategien indeholdt desuden en række
                        temaer, hvortil der var knyttet en bred vifte af forskellige konkrete indsatsområder og initiativer. Et helt
                        centralt initiativ i den nationale strategi var etablering og udvikling af et samlet landsdækkende system til
                        vurdering af kvaliteten i det danske sundhedsvæsen, herunder til formidling af kvalitetsinformationer til brug
                        for den faglige og organisatoriske kvalitetsudvikling og til understøttelse af patienternes frie valg.
                    <br /><br /></p>
                    <p>
                        Inspirationskilden til dette var bl.a. erfaringer med akkreditering gennemført af udenlandske
                        akkrediteringsfirmaer i Hovedstadens Sygehusfællesskab og Sønderjyllands Amt. Det resulterede i DDKM.
                        Det blev aftalt, at Den Danske Kvalitetsmodel, DDKM, skulle udgøre en del af den nationale strategi for
                        kvalitetsudvikling i sundhedsvæsenet, at det skulle være en fælles model til vurdering af kvaliteten af

                        Kvalitetsmodellen skulle baseres på et fælles vurderingsgrundlag bestående                        sundhedsvæsenets ydelser, og at modellen principielt skulle omfatte alle udbydere af offentligt finansierede
                        sundhedsydelser. <br /> af fælles danske standarder med
                        tilhørende indikatorer. Kvalitetsmodellen skulle dertil baseres på et sammenhængende evalueringskoncept,
                        der muliggør en bred, ensartet og integreret kvalitetsvurdering af patientforløb.<br />
                        Et overordnet grundlag for DDKM blev beskrevet i notatet ”Den Danske Kvalitetsmodel for Sundhedsvæsenet,
                        Modelbeskrivelse”, version 1.3, 20.december 2004, udarbejdet af et projektsekretariat i Sundhedsstyrelsens
                        regi, som refererede til en styregruppe med deltagelse fra Sundhedsstyrelsen, Indenrigs- og
                        Sundhedsministeriet, Amtsrådsforeningen og Hovedstadens Sygehusfællesskab.<br />
                        Indenrigs- og Sundhedsministeriet, Amtsrådsforeningen og Hovedstadens Sygehusfællesskab underskrev
                        17.11.2004 en samarbejdsaftale om etablering af en fælles model for kvalitetsvurdering, som førte til, at
                        IKAS blev etableret medio 2005 med den opgave at udvikle og drive DDKM. Kommunernes Landsforening
                        ytrede allerede på dette tidspunkt ønske om at være en del af modellen, og var næsten fra starten med i
                        bestyrelsen for IKAS.
                    <br /><br /></p>

                    <p>
                        I samarbejdsaftalen beskrev man grundprincipperne for DDKM:
                    </p>

                    <p><br />
                        <ul>
                            <li>
                                Den danske kvalitetsmodel skal fremme gode patientforløb, således at patienterne oplever en
                                forbedring af kvaliteten.
                            </li>
                            <li>
                                Kvalitetsmodellen skal understøtte en synliggørelse og formidling af oplysninger om kvaliteten samt
                                fremme en kontinuerlig klinisk, faglig og organisatorisk kvalitetsforbedring af patientforløbene.
                            </li>
                            <li>
                                Kvalitetsmodellen skal være obligatorisk og omfatter principielt alle udbydere og leverandører af
                                offentligt finansierede sundhedsydelser.
                            </li>
                            <li>
                                Kvalitetsmodellen skal være dynamisk og løbende kunne tilpasses borgernes, de fagprofessionelles
                                og myndighedernes behov.
                            </li>
                            <li>
                                Kvalitetsmodellen skal være et samlet, integreret og fælles system til kvalitetsvurdering af strategisk
                                vigtige ydelser og aktiviteter, der har væsentlig betydning for kvaliteten og sikkerheden af
                                sundhedsydelser og patientforløb.
                            </li>
                            <li>
                                Kvalitetsmodellen skal baseres på et fælles vurderingsgrundlag bestående af fælles, danske
                                standarder med tilhørende indikatorer.
                            </li>
                            <li>
                                Kvalitetsmodellen skal understøtte og fremme en systematisk, kontinuerlig selvevaluering og
                                kvalitetsforbedring i sundhedsinstitutionerne og samtidig forestå en periodevis, ensartet og uvildig
                                ekstern evaluering af kvalitetsforbedringen baseret på det fælles vurderingsgrundlag. De eksterne
                                evalueringer skal tilrettelægges, så de kan indgå i en samlet akkreditering af den enkelte
                                sundhedsinstitution.
                            </li>
                        </ul><br />
                    </p>
                    <p>
                        Arbejdet med at udvikle det første akkrediteringsstandardsæt blev påbegyndt, så snart IKAS var etableret.
                    </p>
                    <h2>
                        DDKM efter 2015
                    </h2>
                    <p>
                        I Økonomiaftalen for 2016 mellem regeringen og Danske Regioner blev det besluttet, at akkreditering af
                        offentlige sygehuse skulle udfases og afsluttes ved udgangen af 2015, når der var gennemført to fulde
                        akkrediteringsrunder.<br />
                        Privathospitaler og -klinikker, præhospitalsvæsenet, kommuner samt apoteker skulle stadig tilbydes
                        akkreditering.<br />
                        På baggrund af aftaler i overenskomsterne mellem Danske Regioner og aktører i det primære
                        sundhedsvæsen er der også efter 2015 udviklet akkrediteringsmodeller for professionerne i denne sektor.
                    </p>
                </div>
            </div>
        )
    }
}

export default IkasOmDDKM;