import * as React from 'react';
import { withRouter } from 'react-router-dom';

class AkkrediteringSektorList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {data: []};
        this.getData= this.getData.bind(this);
      }
    getData=()=>{
        let self = this
       fetch('StaticWebsiteData.json'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          console.log(response)
          return response.json()
        })
        .then(function(myJson) {
          console.log(myJson)
          self.setState({data: myJson});
          return myJson
        });
    }

    componentDidMount() {
        this.getData()
    }

    linkedClicked = (value) => {
        this.props.parentCallback(value);
    } 
    render() {

        if(this.state.data === null || typeof this.state.data === 'undefined') {
            // Render loading state ...
            return(
                <div className="row">Loader....</div>
            )
        }
        else {
            return(
                <div className="row">
        
                    <div className="col-md-3" id="left">
                        
                    </div>
                    <div className="col-md-6">
                    
                    <h1>Akkrediteringsnævnets afgørelser for - </h1>
                    {
                        this.state.data.map(item => {
                            return (
                                <h4 style={{cursor:"pointer"}} className="" onClick={() =>{this.props.parentCallback(item.sektor); this.props.history.push("/Afgoerlser");} }>{item.sektor}<div style={{ whiteSpace: 'pre-wrap' }}>&nbsp;</div>
                                </h4>
                            )})
                    }
                    </div>
                    <div className="col-md-3" id="right">
                        
                    </div>
                </div >
            )
        }
    }
}

export default withRouter(AkkrediteringSektorList);