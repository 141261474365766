import * as React from 'react';

class AkkrediteringLeftHtml extends React.Component {

    constructor(props)
    {
        super(props)
    }
    render() {
        return(
            <>
                <div>
                    <p><img src="./img/akkrediteringsnævnet.png" alt="akkrediteringsnævnet" /></p>
                    <p>&nbsp;</p>
                </div>
                <div>
                    {this.props.dataTitle==="Apoteker"
                     || this.props.dataTitle==="Fodterapeutpraksis"
                     || this.props.dataTitle==="Fysioterapipraksis"
                     || this.props.dataTitle==="Privatpraktiserende psykologer" ?
                    <div>
                         <hr />
                        <h4><span>Statusrapport om akkreditering af {this.props.dataTitle}</span></h4>
                        <p><span>Statusrapporten indeholder information om, hvor mange apoteker, der er blevet akkrediteret, samt hvilke indikatorer, der har vist sig udfordrende for klinikkerne.</span></p>
                        <a href={`./Statusrapporter/Statusrapporter.${this.props.dataTitle}.pdf`} title="Læs rapporten her" download="" target="_blank">
                        Læs rapporten her
                        </a>
                    </div> : <p></p> }
                    <br />
                    <hr />
                    <h4><span>Vurderingsprincipper for {this.props.dataTitle}</span></h4>
                    <p><span>Vurderingsprincipperne angiver de overordnede principper, som surveyorne skal bruge, når de skal vurdere et givent fund på et apotek.</span></p>
                    <a href={`./Vurderingsprincipper/Vurderingsprincipper.${this.props.dataTitle}.pdf`} title={`Vurderingsprincipper i DDKM for ${this.props.dataTitle}`} target="_blank">
                    Vurderingsprincipper i DDKM for {this.props.dataTitle}
                    </a>
                </div>
                <hr />
            </>
        )
    }
}

export default AkkrediteringLeftHtml;